//
//  General
//
body {
  background-color: $pro-color-sand;
  background-image: url("../images/camo-bg-v1.jpg"); 
  background-size: cover;
  background-attachment: fixed;
  color: $pro-color-light;
}

#inner-wrapper {
  margin: 0 auto;
  max-width: 75rem;
  width: 100%;
  background-color: $pro-main-bg;
  min-height: 100vh;
}

h1.page-title {
  color: $pro-color-light;
}

a {
  color: $pro-color-sand;
  font-weight: bold;

  &:hover {
    color: lighten($pro-color-sand, 20%);
  }
}

p {
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0
}

.main-block {
  background-color: $pro-main-bg;
  padding-top: 38px;
  padding-bottom: 38px;
  padding-left: 18px;
  padding-right: 18px;
  ul {
    margin-bottom: 0;

    li {
      margin-bottom: 4px;
    }
  }
}

.download-icon {
  max-height: 42px;
  margin-top: 6px;
  margin-bottom: 12px;
}

.padding-left-medium {
  padding-left: 20px;
}

.padding-right-medium {
  padding-right: 20px;
}

.padding-top-large {
  padding-top: 42px;
}

.margin-top-small {
  margin-top: 8px;
}

.padding-bottom-large {
  padding-bottom: 42px;
}

.padding-bottom-medium {
  padding-bottom: 20px;
}

.margin-bottom-small {
  margin-bottom: 6px !important;
}

.background-color-light {
  background-color: $pro-main-bg-light !important;
}

@media #{$small-only} {
  .padding-bottom-medium-medium-down {
    padding-bottom: 32px;
  }
}
@media #{$medium-only} {
  .padding-bottom-medium-medium-down {
    padding-bottom: 32px;
  }
}
@media #{$large-up} {
  .padding-top-medium-large-up {
    padding-top: 32px;
  }
}


//
//  Header
//
#header {
  background-color: $pro-main-bg;
  border-bottom: 3px solid $pro-color-light;
  #logo-wrapper {
    text-align: center;
    #logo {
      max-width: 192px;
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}

//
//  Nav menu
//
#nav {
  border-bottom: 3px solid $pro-color-light;

  .column {
    padding-left: 0;
    padding-right: 0;
  }

  .top-bar {
    .toggle-topbar {
      left: 0;
      a {
        font-size: 18px;
        padding-left: 34px;
      }
    }
    // Mobile menu
    &.expanded {
      .toggle-topbar a {
        color: $pro-color-sand !important;
      }
      .toggle-topbar a span::after {
        box-shadow: 0 0 0 1px $pro-color-sand,
          0 7px 0 1px $pro-color-sand,
          0 14px 0 1px $pro-color-sand;
      }
      ul.left {
        li {
          background-color: $pro-main-bg-light;
        }
      }
    }
  }
  // Dropdown
  .top-bar-section {
    ul.left {
      li {
        a {
          padding-left: 32px;
          padding-right: 32px;
          &.active {
            background-color: $pro-main-bg-light;
          }
        }
      }
    }
  }
}

//
//  Page Start
//
.page-start {
  .quote {
    display: block;
    padding-left: 20px;
    padding-right: 20px;    
    padding-top: 22px;
    font-style: italic;
    color: white;
  }
  .buy-button {
    margin-top: 18px;
    margin-bottom: 0;
    margin-left: 3px;
    margin-right: 3px;
    padding: 12px 18px;
    &:hover {
      background-color: $pro-color-sand;
    }
  }
  #splash-text-small-only {
    padding: 20px;
    background-color: $pro-main-bg-light;

  }
  .main-camera-shot {
    max-width: 300px;
  }
}


//
//  Page Contact
// 
body.page-support {
  h3 {
    margin-top: 22px;
    margin-bottom: 2px;
  }
  span.badge-new {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: $pro-color-light;
    color: $pro-main-bg;
    padding: 2px 6px;
    border-radius: 3px;
    display: inline-block;
    margin-left: 6px;
    vertical-align: middle;
  }
}


//
//  Page Contact
// 
.page-contact {
  p {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

//
//  Footer
//
#footer {
  border-top: 3px solid $pro-color-light;
  padding-top: 42px;
  padding-bottom: 42px;
  @include clearfix();
  .column {
    padding-left: 32px;
    padding-right: 32px;
  }
  p {
    margin-top: 0;
  }
}
