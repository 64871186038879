// Main background color
$pro-main-bg: #323232;

// Lighter version of background, used in mobile navigation
$pro-main-bg-light: lighten($pro-main-bg, 10%); 

// Darker version of background
$pro-main-bg-dark: darken($pro-main-bg, 10%);

// Main "white" color
$pro-color-light: #e2e2e4; 

// Sand-colored version of white-color, used for links and hover-effects
$pro-color-sand: #ada28a; 

// Darker version of sand, used for hover effects
$pro-color-sand-dark: darken(#ada28a, 20%); 
